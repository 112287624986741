// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-04-templates-blog-post-tsx": () => import("./../../../src/components/04_templates/blog-post.tsx" /* webpackChunkName: "component---src-components-04-templates-blog-post-tsx" */),
  "component---src-components-04-templates-tags-tsx": () => import("./../../../src/components/04_templates/tags.tsx" /* webpackChunkName: "component---src-components-04-templates-tags-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-policy-bungo-tsx": () => import("./../../../src/pages/app/policy-bungo.tsx" /* webpackChunkName: "component---src-pages-app-policy-bungo-tsx" */),
  "component---src-pages-app-policy-tsx": () => import("./../../../src/pages/app/policy.tsx" /* webpackChunkName: "component---src-pages-app-policy-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-coffeememo-tsx": () => import("./../../../src/pages/lp/coffeememo.tsx" /* webpackChunkName: "component---src-pages-lp-coffeememo-tsx" */),
  "component---src-pages-lp-foldest-tsx": () => import("./../../../src/pages/lp/foldest.tsx" /* webpackChunkName: "component---src-pages-lp-foldest-tsx" */),
  "component---src-pages-lp-mdediter-tsx": () => import("./../../../src/pages/lp/mdediter.tsx" /* webpackChunkName: "component---src-pages-lp-mdediter-tsx" */),
  "component---src-pages-lp-noty-tsx": () => import("./../../../src/pages/lp/noty.tsx" /* webpackChunkName: "component---src-pages-lp-noty-tsx" */),
  "component---src-pages-my-files-tsx": () => import("./../../../src/pages/my-files.tsx" /* webpackChunkName: "component---src-pages-my-files-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */)
}

